import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors } from '../styledComponents';

const ClothRow = styled.div`
  background-color: ${colors.navy};
  color: white;
  padding-left: 5px;
`;

const SummaryRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 5px;
`;

const getClothName = (cloth, pricingInverse) => {
  try {
    return pricingInverse[cloth.items[0].id].cloth;
  } catch (e) {
    return cloth.slug;
  }
};

const OrderSummaryCloth = ({ cloth, pricingInverse }) => {
  if (!cloth.items && !cloth.items[0]) {
    return null;
  }
  const clothName = getClothName(cloth, pricingInverse);
  return (
    <div>
      <ClothRow>
        {clothName}
      </ClothRow>
      {cloth && cloth.items && cloth.items.map((item, index) => ({ ...item, index })).map((item) => {
        const refItem = pricingInverse[item.id];
        if (!refItem) return null;
        const name = `${refItem.category} - ${refItem.name}`;
        const price = item.price * item.quantity;
        return (
          <SummaryRow key={`${item.index}_${name}`}>
            <span style={{ flex: 3 }}>
              {name}
            </span>
            <span style={{ flex: 1, textAlign: 'right' }}>
              {item.quantity}
              X
            </span>
            <span style={{ flex: 1, textAlign: 'right' }}>
              {price}
              €
            </span>
          </SummaryRow>
        );
      })}
    </div>
  );
};

OrderSummaryCloth.propTypes = {
  cloth: PropTypes.shape({}).isRequired,
  pricingInverse: PropTypes.shape({}).isRequired,
};

export default OrderSummaryCloth;
