import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import OrderCard from './OrderCard';
import { colors } from '../styledComponents';
import routesMap from '../../Routes';

const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: ${colors.navy};
  font-size: ${(props) => props.small ? '36px' : '48px'};
  font-weight: 300;
`;

const Text = styled.p`
  text-align: center;
`;

const OrdersList = ({ orders }) => (
  <div>
    <Title>Mes commandes</Title>
    <div>
      {orders && orders.length > 0
        ? orders.map((order) => <OrderCard key={order._id} order={order} />)
        : (
          <Text>
            Pas encore de commande. Pour commander, c‘est
            <Link to={routesMap.Step1.url}>ici</Link>
          </Text>
        )}
    </div>
  </div>
);

OrdersList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default OrdersList;
